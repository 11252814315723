import cn from 'classnames';
import Image from 'next/image';
import { useMemo } from 'react';
import { Autoplay, FreeMode, Navigation, Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import type { Banner, DefaultCmsDataResponse } from '@/api/cms/types';
import ButtonNavSwiper from '@/components/ButtonNavSwiper';
import useIsMobile from '@/lib/useIsMobile';
import 'swiper/css';
import 'swiper/css/autoplay';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import styles from './ImageBanner.module.css';

type Props = {
  bannerData: DefaultCmsDataResponse<Banner> | null;
};

type BannerImage = {
  id: number;
  url: string;
  link: string;
  alt: string;
};

export default function BannerSwiper({ bannerData }: Props) {
  const isMobile = useIsMobile();

  const bannerDesktopData: BannerImage[] = useMemo(
    () =>
      bannerData?.attributes?.items
        ?.filter((item) => item.type === 'home' && item.desktop_image)
        .map((item) => ({
          id: item.id,
          link: item.link,
          alt: item.name,
          url: item.desktop_image?.data?.attributes?.url,
        })) || [],
    [bannerData]
  );

  const bannerMobileData: BannerImage[] = useMemo(
    () =>
      bannerData?.attributes?.items
        ?.filter((item) => item.type === 'home' && item.mobile_image)
        .map((item) => ({
          id: item.id,
          link: item.link,
          alt: item.name,
          url: item.mobile_image?.data?.attributes?.url,
        })) || [],
    [bannerData]
  );

  return (
    <>
      {bannerData ? (
        <section className="home-banner desktop:mt-8 desktop:px-4 max-w-8xl relative mx-auto flex w-full flex-col items-center justify-center overflow-hidden">
          <div className="max-w-8xl desktop:px-8 absolute inset-x-auto top-1/2 z-10 mx-auto flex min-w-full -translate-y-1/2 items-center justify-between px-4">
            <ButtonNavSwiper type="left" className="col-span-1" />
            <ButtonNavSwiper type="right" className="col-span-1" />
          </div>
          <Swiper
            modules={[Navigation, Pagination, Autoplay, FreeMode]}
            slidesPerView={1}
            loop={true}
            navigation={{ prevEl: '.prevEl', nextEl: '.nextEl' }}
            autoplay={{ delay: 5000 }}
            pagination={{ clickable: true }}
            className="desktop:aspect-[32/15] desktop:h-auto desktop:rounded-3xl aspect-[11/20] w-full overflow-hidden"
          >
            {!isMobile
              ? bannerDesktopData.map((item) => (
                  <SwiperSlide key={item.id}>
                    <div className="unsetImg relative w-full bg-transparent">
                      <Image
                        src={item.url}
                        alt={item.alt}
                        fill
                        className={styles.customImg}
                      />
                    </div>
                  </SwiperSlide>
                ))
              : bannerMobileData.map((item) => (
                  <SwiperSlide key={item.id}>
                    <div className="unsetImg relative w-full bg-transparent">
                      <Image
                        src={item.url}
                        alt={item.alt}
                        fill
                        className={styles.customImg}
                      />
                    </div>
                  </SwiperSlide>
                ))}
          </Swiper>
        </section>
      ) : (
        <div
          className={cn(
            'desktop:aspect-[32/15] desktop:h-auto relative aspect-[11/20] w-full bg-transparent',
            {
              hidden: isMobile,
            }
          )}
        >
          <Image
            src={
              !isMobile
                ? '/static/images/home/banner-faster-delivery-desktop.webp'
                : '/static/images/home/banner-faster-delivery-mobile.webp'
            }
            fill
            // style={{ objectFit: 'cover' }}
            alt="Ahamove - Giải pháp hoàn hảo cho mọi nhu cầu của bạn"
          />
        </div>
      )}
    </>
  );
}
