export const homePoliciesData = [
  {
    icon: '/static/icons/home/MoneyBagDriver.svg',
    title: 'Thu nhập hấp dẫn',
    desc: 'Nhận tiền sau mỗi chuyến xe và nhiều chương trình thưởng khác',
  },
  {
    icon: '/static/icons/home/ClockDriver.svg',
    title: 'Làm chủ thời gian',
    desc: 'Nhận đơn để kiếm thêm thu nhập bất cứ khi nào bạn muốn',
  },
  {
    icon: '/static/icons/home/LikeDriver.svg',
    title: 'Đăng ký dễ dàng',
    desc: 'Chúng tôi sẽ liên lạc để hỗ trợ sau khi bạn điền thông tin',
  },
];

export const homeServicesData = [
  {
    id: 's1',
    icon: '/static/icons/AhaShip.svg',
    title: 'Giao hàng',
    desc: 'Giao hàng nhanh, đảm bảo và giá tốt với dịch vụ phù hợp được tối ưu cho bạn.',
  },
  {
    id: 's2',
    icon: '/static/icons/AhaTruck.svg',
    title: 'AhaTruck',
    desc: 'Đa dạng phương tiện vận chuyển dành cho các mặt hàng lớn và cồng kềnh.',
  },
  {
    id: 's3',
    icon: '/static/icons/AhaMart.svg',
    title: 'AhaMart',
    desc: 'Mua sắm hằng ngày thật đơn giản và tiện lợi. Bạn cần gì chúng tôi giao tận nhà.',
  },
  {
    id: 's4',
    icon: '/static/icons/AhaSupply.svg',
    title: 'AhaSupply',
    desc: 'Cung cấp nguồn hàng sỉ nhanh chóng và bảo đảm với mức giá vô cùng cạnh tranh.',
  },
];

export const homeStatisticsData = [
  { quantity: '700,000+', desc: 'Doanh nghiệp/hộ kinh doanh trên toàn quốc' },
  { quantity: '2,500,000+', desc: 'Khách hàng tin tưởng sử dụng dịch vụ' },
  { quantity: '20', desc: 'Tỉnh thành có sự hiện diện của Ahamove' },
  { quantity: '200,000', desc: 'Điểm giao thành công mỗi ngày' },
];
