import cn from 'classnames';
import ArrowNarrowDownIcon from './icons/ArrowNarrowDownIcon';
import ArrowNarrowLeftIcon from './icons/ArrowNarrowLeftIcon';
import ArrowNarrowRightIcon from './icons/ArrowNarrowRightIcon';
import ArrowNarrowUpIcon from './icons/ArrowNarrowUpIcon';

type Props = {
  type: 'left' | 'right';
  vertical?: boolean;
  className?: string;
};

export default function ButtonNavSwiper({
  type,
  vertical = false,
  className,
}: Props) {
  const classIcon =
    'fill-neutral-40 desktop:enabled:group-active:fill-white desktop:group-enabled:group-hover:fill-white';
  return (
    <button
      aria-label="button"
      className={cn(
        'shadow-shadow8 desktop:enabled:hover:bg-primary-50 desktop:enabled:active:bg-primary-60 group flex h-12 w-12 items-center justify-center rounded-full bg-white disabled:opacity-50',
        { nextEl: type === 'right' },
        { prevEl: type === 'left' },
        className
      )}
    >
      {type === 'right' ? (
        vertical ? (
          <ArrowNarrowDownIcon className={cn(classIcon)} />
        ) : (
          <ArrowNarrowRightIcon className={cn(classIcon)} />
        )
      ) : vertical ? (
        <ArrowNarrowUpIcon className={cn(classIcon)} />
      ) : (
        <ArrowNarrowLeftIcon className={cn(classIcon)} />
      )}
    </button>
  );
}
